<template>
  <ContentWrapper>
    <breadcrumb/>

    <div class="container">
      <buy-event-tickets></buy-event-tickets>
    </div>

  </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>
import BuyEventTickets from "../components/BuyEventTickets";

export default {
  components: {
    BuyEventTickets,
  },
}
</script>