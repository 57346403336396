<template>
  <div>
    <div class="alert alert-danger animated-fast fadeInDown">
      {{ $t('appointment.component.buy_event_tickets.hint_cancellation') }} <a target="_blank" :href="$t('appointment.component.buy_event_tickets.hint_cancellation_link')">-> Blog Post <-</a>
    </div>
    <div>
      <div v-if="tickets.length" class="card card-default animated-fast fadeInDown">
        <div class="card-header">
          <i class="fad fa-ticket mr-2"></i> {{ $t('appointment.component.appointment_request.tickets_heading') }}
        </div>

        <div class="card-body">
          <b-row>
            <b-col lg="6" v-for="ticket in tickets" :key="ticket.label">
              <ticket :ticket="ticket"></ticket>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Ticket from "./Ticket";

export default {
  components: {
    Ticket
  },
  data() {
    return {
      event_id: 373,
      tickets: []
    }
  },
  beforeMount() {
    this.$api.get('appointment/tickets?appointment_id=' + this.event_id).then(response => {
      this.tickets = response.data.tickets;
    });
  }
}
</script>