<template>
  <div class="card card-default card-ticket" :class="{'ticket-unregistered': !ticket.registered_at}">
    <div class="card-body bg-white p-0">
      <div class="p-1">
        <div class="ticket-label">{{ ticket.label }}</div>
      </div>
      <div class="p-3 w-100">
        <span v-if="ticket.registered_at">Name: <b>{{ ticket.firstname }} {{ ticket.lastname }}</b></span>
        <span v-else="ticket.registered_at">Name: <b>Registration required!</b></span>
        <br/><span>Starts at: <b>{{ ticket.appointment.start_at | moment('utc','LLLL') }}</b></span>
      </div>
      <div class="p-0 text-right" v-if="!ticket.registered_at">
        <b-btn :to="{name: 'Appointment:AppointmentRegisterTicket', params: {label: ticket.label}}" variant="link" class="float-right bg-white px-1 py-0 m-1"
               title="Register Ticket" target="_self"><i class="fad fa-edit fa-2x"></i>
        </b-btn>
        <b-btn @click="transfer" variant="link" class="float-right bg-white px-1 py-0 m-1"
               title="Manage plants" target="_self"><i class="fad fa-exchange fa-2x"></i>
        </b-btn><br/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['ticket'],
  data() {
    return {}
  },
  methods: {
    transfer() {
      this.$swal.fire({
        title: 'Ticket Transfer',
        html: 'You\'re going to transfer the ticket: <br/><strong>' + this.ticket.label + '</strong><br/><br/>' +
            'You can transfer a ticket once to another user.',
        input: 'text',
        inputPlaceholder: 'Enter username here',
        confirmButtonText: 'Confirm Transfer <i class="fad fa-exclamation-triangle"></i>',
        showCancelButton: true,
        reverseButtons: true
      }).then((response) => {
        if (response.value) {
          this.$auth.requestVerfificationCode().then(code => {
            return this.$api.post('appointment/ticket/' + this.ticket.label + '/transfer', {
              username: response.value,
              verification_code: code.verification_code
            })
          }).then(() => {
            this.$swal.fire({
              title: this.$t('appointment.component.ticket.popup.payment_confirmed.title'),
              text: this.$t('appointment.component.ticket.popup.payment_confirmed.text')
            })

            //todo clean solution
            this.$parent.loadTickets();
          }).catch(response => {
            this.$swal.fire('Failed', response.data.message);
          })
        }
      });
    },
  }
}
</script>